import { APPROVAL_MESSAGES_ARRAY, TOPIC } from "../../utils/constants";
import { socket } from "../../utils/socket";

export const handleEmitApprovalCounts = () => {

    const approvalTrackingStatus = [APPROVAL_MESSAGES_ARRAY[0]?._id];

    const exchangeHouseApproval = {
        approvalTrackingStatus,
        notIncludeTopics: [TOPIC.ON_BOARD_REQUEST, TOPIC.EXCHANGE_HOUSE_BRANCH_ONBOARD_REQUEST],
    }

    const exchangeHouseOnBoardApproval = {
        approvalTrackingStatus,
        topicsInclude: [TOPIC.ON_BOARD_REQUEST, TOPIC.EXCHANGE_HOUSE_BRANCH_ONBOARD_REQUEST],
    }

    const payload = { exchangeHouseApproval, exchangeHouseOnBoardApproval };

    socket.emit("approvalCounts", payload);

};