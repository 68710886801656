import { post, get } from "../../utils/apiMethods";
import CommonConstant from "../constants/Common.constant";
import { handleError, handleSuccess } from "../../utils/methods";
import { setValueIntoHeaders, setValueInToLocalStorage } from "../../utils/asyncStorage/Functions";
import { BRANCH, BRANCH_ID, CLIENT, CLIENT_ID } from "../../utils/asyncStorage/Constants";

export const toggleLeftDrawer = (value) => (dispatch) => {
    dispatch({ type: CommonConstant.TOGGLE_LEFT_DRAWER, value: value })
};

export const selectCountry = (value) => (dispatch) => {
    let selectedCountry = {
        "name": "United Arab Emirates",
        "code": "+971",
        "iso": "AE",
        "flag": "https://www.countryflags.io/AE/flat/24.png",
        "mask": ["99 999 9999", "9 999 9999"],
        pattern: [/\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]
    }
    dispatch({ type: CommonConstant.CURRENT_COUNTRY, country: selectedCountry })
};

export const getCountries = (values, CB) => (dispatch) => {
    dispatch({ type: CommonConstant.GET_COUNTRIES, loading: true });
    post(`masters/getAllCountries`, values)
        .then(async ({ data }) => {
            if (!data.error) {
                dispatch({ type: CommonConstant.GET_COUNTRIES, loading: false, data: data?.data?.entries });
            } else {
                handleError(data?.data?.message || 'Something went wrong!');
                dispatch({ type: CommonConstant.GET_COUNTRIES, loading: false });
            }
        })
        .catch((error) => {
            handleError(error?.data?.message || error?.response?.data?.message || 'Something went wrong!');
            dispatch({ type: CommonConstant.GET_COUNTRIES, loading: false });
        });
};

export const getStates = (values, CB) => (dispatch) => {
    dispatch({ type: CommonConstant.GET_STATES, loading: true });
    post(`masters/getAllStates`, values)
        .then(async ({ data }) => {
            if (!data.error) {
                dispatch({ type: CommonConstant.GET_STATES, loading: false, data: data?.data?.entries });
            } else {
                handleError(data?.data?.message || 'Something went wrong!');
                dispatch({ type: CommonConstant.GET_STATES, loading: false });
            }
        })
        .catch((error) => {
            handleError(error?.data?.message || error?.response?.data?.message || 'Something went wrong!');
            dispatch({ type: CommonConstant.GET_STATES, loading: false });
        });
};

export const getClients = (values, CB, type) => (dispatch) => {
    if (type) {
        dispatch({ type: CommonConstant.GET_CLIENTS, loading: false, data: values });
    } else {
        dispatch({ type: CommonConstant.GET_CLIENTS, loading: true });
        post(`clients/getAll`, values)
            .then(async ({ data }) => {
                if (!data.error) {
                    dispatch({ type: CommonConstant.GET_CLIENTS, loading: false, data: data?.data?.entries });
                    CB && CB(data?.data?.entries);
                } else {
                    handleError(data?.data?.message || 'Something went wrong!');
                    dispatch({ type: CommonConstant.GET_CLIENTS, loading: false });
                }
            })
            .catch((error) => {
                handleError(error?.data?.message || error?.response?.data?.message || 'Something went wrong!');
                dispatch({ type: CommonConstant.GET_CLIENTS, loading: false });
            });
    }
};

export const changeClient = (client = {}, CB) => (dispatch) => {
    setValueInToLocalStorage(CLIENT_ID, client?._id || '');
    setValueInToLocalStorage(CLIENT, JSON.stringify(client));
    setValueIntoHeaders(client?._id || '', 'clientId');
    dispatch({ type: CommonConstant.SELECTED_CLIENT, data: client?._id || '', obj: client || {} });
    CB && CB();
};

export const getBranches = (values, CB, type) => (dispatch) => {
    if (type) {
        dispatch({ type: CommonConstant.GET_BRANCHES_COMMON, loading: false, data: values });
    } else {
        dispatch({ type: CommonConstant.GET_BRANCHES_COMMON, loading: true });
        post(`exchangeHouse/getBranches`, values)
            .then(async ({ data }) => {
                if (!data.error) {
                    dispatch({ type: CommonConstant.GET_BRANCHES_COMMON, loading: false, data: data?.data?.entries });
                } else {
                    handleError(data?.data?.message);
                    dispatch({ type: CommonConstant.GET_BRANCHES_COMMON, loading: false });
                }
            })
            .catch((error) => {
                handleError(error?.data?.message || error?.response?.data?.message || 'Something went wrong!');
                dispatch({ type: CommonConstant.GET_BRANCHES_COMMON, loading: false });
            });
    }
};

export const changeBranch = (branch, CB, setClient = true) => (dispatch) => {
    setValueInToLocalStorage(BRANCH_ID, branch?._id || '');
    setValueInToLocalStorage(BRANCH, JSON.stringify(branch));
    setValueIntoHeaders(branch?._id || '', 'exchangeHouseBranch');
    dispatch({ type: CommonConstant.SELECTED_BRANCH, data: branch?._id || '', obj: branch || {} });
    if (setClient) {
        dispatch(changeClient(branch?.client || {}));
    } else {
        dispatch(changeClient({}));
    }
    CB && CB(branch?._id || '');
};

export const downloadCSV = (payload, uri, fileName) => (dispatch) => {
    let dispatchType = CommonConstant.DOWNLOAD_CSV;
    dispatch({ type: dispatchType, loading: true });
    post(uri, payload)
        .then(async ({ data }) => {
            try {
                let link = document.createElement("a");
                let d = new Date();
                link.download = `${fileName}-${d.toISOString()}.csv`;
                let blob = new Blob([data], { type: "text/csv" });
                link.href = URL.createObjectURL(blob);
                link.click();
                URL.revokeObjectURL(link.href);
                dispatch({ type: dispatchType, loading: false });
            } catch (err) {
                console.log('error', err);
            }
        })
        .catch((error) => {
            handleError(error?.data?.message || error?.response?.data?.message || "Something went wrong!");
            dispatch({ type: dispatchType, loading: false });
        });
};

export const resetAuthCode = (payload, CB) => async (dispatch) => {
    const dispatchType = CommonConstant.RESET_AUTH_CODE;
    dispatch({ type: dispatchType, loading: true });
    post(`generic/resetGoogleAuth`, payload)
        .then(async ({ data }) => {
            if (!data.error) {
                dispatch({ type: dispatchType, loading: false });
                handleSuccess(data?.data?.message || 'Authentication Code Reset Successfully!');
                CB && CB();
            } else {
                handleError(data?.data?.message || 'Something went wrong!');
                dispatch({ type: dispatchType, loading: false });
            }
        })
        .catch((error) => {
            dispatch({ type: dispatchType, loading: false });
            handleError(error?.response?.data?.message || error?.data?.message || 'Something went wrong!');
        });
};

export const syncBranchClient = (payload, CB) => async (dispatch) => {
    const dispatchType = CommonConstant.SYNC_BRANCH_CLIENT;
    dispatch({ type: dispatchType, loading: true });
    post(`admins/getEmp`, payload)
        .then(async ({ data }) => {
            if (data?.error) {
                dispatch({ type: dispatchType, loading: false });
                handleError(data?.data?.message || "Something went wrong!");
            } else {
                dispatch({ type: dispatchType, loading: false });
                handleSuccess(data?.data?.message || "Successfully Syncronized!");
                CB && CB();
            }
        })
        .catch((error) => {
            dispatch({ type: dispatchType, loading: false });
            handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
        });
};

export const refreshTokenTimer = (isTimer) => (dispatch) => {
    let dispatchType = CommonConstant.REFRESH_TOKEN_TIMER;
    dispatch({ type: dispatchType, data: isTimer });
};

export const setApprovalsCount = (response) => (dispatch) => {
    const dispatchType = CommonConstant.GET_APPROVAL_COUNT;
    dispatch({
        type: dispatchType,
        ...response
    });
};

export const getConstants = (CB) => (dispatch) => {
    const dispatchType = CommonConstant.GET_CONSTANTS;
    dispatch({ type: dispatchType, loading: true });
    get(`generic/getConstants`)
        .then(async ({ data }) => {
            if (!data.error) {
                let employeesDocs = data?.data?.DOCUMENT_TYPES?.filter(doc => doc?.docType === 'E')?.sort((a, b) => { return a?.docId - b?.docId });
                let employerDocs = data?.data?.DOCUMENT_TYPES?.filter(doc => doc?.docType === 'C')?.sort((a, b) => { return a?.docId - b?.docId });
                dispatch({ type: dispatchType, loading: false, data: data?.data, employeesDocs, employerDocs });
                CB && CB();
            } else {
                handleError(data?.data?.message || 'Something went wrong!');
                dispatch({ type: dispatchType, loading: false });
            }
        })
        .catch((error) => {
            handleError(error?.data?.message || error?.response?.data?.message || 'Something went wrong!');
            dispatch({ type: dispatchType, loading: false });
        });
};