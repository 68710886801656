import CommonConstant from "../constants/Common.constant";

const initialState = {
    isOpenLeftDrawer: false,

    currentCountry: {},

    countries: [],
    getCountriesLoading: false,

    states: [],
    getStatesLoading: false,

    clients: [],
    getClientsLoading: false,

    selectedClient: '',
    selectedClientObj: {},

    branches: [],
    getBranchesLoading: false,

    selectedBranch: '',
    selectedBranchObj: {},

    downloadCSVLoading: false,

    resetAuthCodeLoading: false,

    syncBranchClientLoading: false,

    isTimerOn: false,

    clientApproval: 0,
    onBoardApproval: 0,

    constants: {},
    getConstantsLoading: false,
    employerDocs: [],
    employeesDocs: [],
};

export default (state = initialState, action = {}) => {
    switch (action.type) {

        case CommonConstant.TOGGLE_LEFT_DRAWER:
            return { ...state, isOpenLeftDrawer: action.value };

        case CommonConstant.CURRENT_COUNTRY:
            return { ...state, currentCountry: action.country };


        case CommonConstant.GET_COUNTRIES:
            return {
                ...state,
                getCountriesLoading: action.loading,
                countries: action.data,
            };

        case CommonConstant.GET_STATES:
            return {
                ...state,
                getStatesLoading: action.loading,
                states: action.data,
            };

        case CommonConstant.GET_CLIENTS:
            return { ...state, getClientsLoading: action.loading, clients: action.data };

        case CommonConstant.SELECTED_CLIENT:
            return { ...state, selectedClient: action.data, selectedClientObj: action.obj };

        case CommonConstant.GET_BRANCHES_COMMON:
            return { ...state, getBranchesLoading: action.loading, branches: action.data };

        case CommonConstant.SELECTED_BRANCH:
            return { ...state, selectedBranch: action.data, selectedBranchObj: action.obj };

        case CommonConstant.DOWNLOAD_CSV:
            return { ...state, downloadCSVLoading: action.loading };

        case CommonConstant.RESET_AUTH_CODE:
            return { ...state, resetAuthCodeLoading: action.loading };

        case CommonConstant.SYNC_BRANCH_CLIENT:
            return { ...state, syncBranchClientLoading: action.loading };

        case CommonConstant.REFRESH_TOKEN_TIMER:
            return { ...state, isTimerOn: action.data };

        case CommonConstant.GET_APPROVAL_COUNT:
            return {
                ...state,
                clientApproval: action.clientApproval,
                onBoardApproval: action.onBoardApproval,
            };

        case CommonConstant.GET_CONSTANTS:
            return {
                ...state,
                constants: action.data,
                employerDocs: action.employerDocs,
                employeesDocs: action.employeesDocs,
                getConstantsLoading: action.loading,
            };


        default:
            return state;
    }
};
